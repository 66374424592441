exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-build-your-own-online-store-js": () => import("./../../../src/pages/build-your-own-online-store.js" /* webpackChunkName: "component---src-pages-build-your-own-online-store-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-forms-build-your-own-online-store-js": () => import("./../../../src/pages/forms/build-your-own-online-store.js" /* webpackChunkName: "component---src-pages-forms-build-your-own-online-store-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reads-dropshipping-misconceptions-js": () => import("./../../../src/pages/reads/dropshipping-misconceptions.js" /* webpackChunkName: "component---src-pages-reads-dropshipping-misconceptions-js" */),
  "component---src-pages-reads-why-quit-amazon-js": () => import("./../../../src/pages/reads/why-quit-amazon.js" /* webpackChunkName: "component---src-pages-reads-why-quit-amazon-js" */),
  "component---src-pages-restaurant-websites-js": () => import("./../../../src/pages/restaurant-websites.js" /* webpackChunkName: "component---src-pages-restaurant-websites-js" */),
  "component---src-pages-services-branding-applied-js": () => import("./../../../src/pages/services/branding-applied.js" /* webpackChunkName: "component---src-pages-services-branding-applied-js" */),
  "component---src-pages-services-branding-js": () => import("./../../../src/pages/services/branding.js" /* webpackChunkName: "component---src-pages-services-branding-js" */),
  "component---src-pages-services-business-card-js": () => import("./../../../src/pages/services/business-card.js" /* webpackChunkName: "component---src-pages-services-business-card-js" */),
  "component---src-pages-services-complete-online-store-js": () => import("./../../../src/pages/services/complete-online-store.js" /* webpackChunkName: "component---src-pages-services-complete-online-store-js" */),
  "component---src-pages-services-connecting-a-new-domain-js": () => import("./../../../src/pages/services/connecting-a-new-domain.js" /* webpackChunkName: "component---src-pages-services-connecting-a-new-domain-js" */),
  "component---src-pages-services-cookie-policy-js": () => import("./../../../src/pages/services/cookie-policy.js" /* webpackChunkName: "component---src-pages-services-cookie-policy-js" */),
  "component---src-pages-services-cookie-popup-js": () => import("./../../../src/pages/services/cookie-popup.js" /* webpackChunkName: "component---src-pages-services-cookie-popup-js" */),
  "component---src-pages-services-custom-email-address-js": () => import("./../../../src/pages/services/custom-email-address.js" /* webpackChunkName: "component---src-pages-services-custom-email-address-js" */),
  "component---src-pages-services-flyer-design-js": () => import("./../../../src/pages/services/flyer-design.js" /* webpackChunkName: "component---src-pages-services-flyer-design-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-online-store-build-js": () => import("./../../../src/pages/services/online-store-build.js" /* webpackChunkName: "component---src-pages-services-online-store-build-js" */),
  "component---src-pages-services-online-store-design-js": () => import("./../../../src/pages/services/online-store-design.js" /* webpackChunkName: "component---src-pages-services-online-store-design-js" */),
  "component---src-pages-services-photo-background-removal-js": () => import("./../../../src/pages/services/photo-background-removal.js" /* webpackChunkName: "component---src-pages-services-photo-background-removal-js" */),
  "component---src-pages-services-privacy-policy-js": () => import("./../../../src/pages/services/privacy-policy.js" /* webpackChunkName: "component---src-pages-services-privacy-policy-js" */),
  "component---src-pages-services-product-descriptions-js": () => import("./../../../src/pages/services/product-descriptions.js" /* webpackChunkName: "component---src-pages-services-product-descriptions-js" */),
  "component---src-pages-services-product-photo-editing-js": () => import("./../../../src/pages/services/product-photo-editing.js" /* webpackChunkName: "component---src-pages-services-product-photo-editing-js" */),
  "component---src-pages-services-returns-policy-js": () => import("./../../../src/pages/services/returns-policy.js" /* webpackChunkName: "component---src-pages-services-returns-policy-js" */),
  "component---src-pages-services-shopify-store-setup-js": () => import("./../../../src/pages/services/shopify-store-setup.js" /* webpackChunkName: "component---src-pages-services-shopify-store-setup-js" */),
  "component---src-pages-services-tech-support-js": () => import("./../../../src/pages/services/tech-support.js" /* webpackChunkName: "component---src-pages-services-tech-support-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-themes-index-js": () => import("./../../../src/pages/themes/index.js" /* webpackChunkName: "component---src-pages-themes-index-js" */),
  "component---src-pages-themes-takeout-features-image-filters-js": () => import("./../../../src/pages/themes/takeout/features/image-filters.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-image-filters-js" */),
  "component---src-pages-themes-takeout-features-layout-control-js": () => import("./../../../src/pages/themes/takeout/features/layout-control.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-layout-control-js" */),
  "component---src-pages-themes-takeout-features-menu-grid-view-js": () => import("./../../../src/pages/themes/takeout/features/menu-grid-view.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-menu-grid-view-js" */),
  "component---src-pages-themes-takeout-features-mobile-experience-js": () => import("./../../../src/pages/themes/takeout/features/mobile-experience.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-mobile-experience-js" */),
  "component---src-pages-themes-takeout-features-opening-hours-js": () => import("./../../../src/pages/themes/takeout/features/opening-hours.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-opening-hours-js" */),
  "component---src-pages-themes-takeout-features-shopping-experience-js": () => import("./../../../src/pages/themes/takeout/features/shopping-experience.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-shopping-experience-js" */),
  "component---src-pages-themes-takeout-features-storytelling-js": () => import("./../../../src/pages/themes/takeout/features/storytelling.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-storytelling-js" */),
  "component---src-pages-themes-takeout-features-tags-js": () => import("./../../../src/pages/themes/takeout/features/tags.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-tags-js" */),
  "component---src-pages-themes-takeout-help-request-a-feature-js": () => import("./../../../src/pages/themes/takeout/help/request-a-feature.js" /* webpackChunkName: "component---src-pages-themes-takeout-help-request-a-feature-js" */),
  "component---src-pages-themes-takeout-help-theme-support-js": () => import("./../../../src/pages/themes/takeout/help/theme-support.js" /* webpackChunkName: "component---src-pages-themes-takeout-help-theme-support-js" */),
  "component---src-pages-themes-takeout-index-js": () => import("./../../../src/pages/themes/takeout/index.js" /* webpackChunkName: "component---src-pages-themes-takeout-index-js" */),
  "component---src-pages-themes-takeout-pages-404-js": () => import("./../../../src/pages/themes/takeout/pages/404.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-404-js" */),
  "component---src-pages-themes-takeout-pages-blog-js": () => import("./../../../src/pages/themes/takeout/pages/blog.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-blog-js" */),
  "component---src-pages-themes-takeout-pages-blog-post-js": () => import("./../../../src/pages/themes/takeout/pages/blog-post.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-blog-post-js" */),
  "component---src-pages-themes-takeout-pages-cart-js": () => import("./../../../src/pages/themes/takeout/pages/cart.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-cart-js" */),
  "component---src-pages-themes-takeout-pages-collection-js": () => import("./../../../src/pages/themes/takeout/pages/collection.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-collection-js" */),
  "component---src-pages-themes-takeout-pages-collection-list-js": () => import("./../../../src/pages/themes/takeout/pages/collection-list.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-collection-list-js" */),
  "component---src-pages-themes-takeout-pages-contact-js": () => import("./../../../src/pages/themes/takeout/pages/contact.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-contact-js" */),
  "component---src-pages-themes-takeout-pages-gift-card-js": () => import("./../../../src/pages/themes/takeout/pages/gift-card.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-gift-card-js" */),
  "component---src-pages-themes-takeout-pages-page-js": () => import("./../../../src/pages/themes/takeout/pages/page.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-page-js" */),
  "component---src-pages-themes-takeout-pages-password-js": () => import("./../../../src/pages/themes/takeout/pages/password.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-password-js" */),
  "component---src-pages-themes-takeout-pages-product-js": () => import("./../../../src/pages/themes/takeout/pages/product.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-product-js" */),
  "component---src-pages-themes-takeout-pages-search-js": () => import("./../../../src/pages/themes/takeout/pages/search.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-search-js" */),
  "component---src-pages-themes-takeout-sections-announcement-js": () => import("./../../../src/pages/themes/takeout/sections/announcement.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-announcement-js" */),
  "component---src-pages-themes-takeout-sections-blog-posts-js": () => import("./../../../src/pages/themes/takeout/sections/blog-posts.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-blog-posts-js" */),
  "component---src-pages-themes-takeout-sections-collage-js": () => import("./../../../src/pages/themes/takeout/sections/collage.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-collage-js" */),
  "component---src-pages-themes-takeout-sections-collapsible-content-js": () => import("./../../../src/pages/themes/takeout/sections/collapsible-content.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-collapsible-content-js" */),
  "component---src-pages-themes-takeout-sections-collection-list-js": () => import("./../../../src/pages/themes/takeout/sections/collection-list.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-collection-list-js" */),
  "component---src-pages-themes-takeout-sections-contact-form-js": () => import("./../../../src/pages/themes/takeout/sections/contact-form.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-contact-form-js" */),
  "component---src-pages-themes-takeout-sections-countdown-banner-js": () => import("./../../../src/pages/themes/takeout/sections/countdown-banner.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-countdown-banner-js" */),
  "component---src-pages-themes-takeout-sections-custom-liquid-js": () => import("./../../../src/pages/themes/takeout/sections/custom-liquid.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-custom-liquid-js" */),
  "component---src-pages-themes-takeout-sections-divider-js": () => import("./../../../src/pages/themes/takeout/sections/divider.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-divider-js" */),
  "component---src-pages-themes-takeout-sections-email-signup-js": () => import("./../../../src/pages/themes/takeout/sections/email-signup.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-email-signup-js" */),
  "component---src-pages-themes-takeout-sections-featured-collection-js": () => import("./../../../src/pages/themes/takeout/sections/featured-collection.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-featured-collection-js" */),
  "component---src-pages-themes-takeout-sections-featured-product-js": () => import("./../../../src/pages/themes/takeout/sections/featured-product.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-featured-product-js" */),
  "component---src-pages-themes-takeout-sections-footer-js": () => import("./../../../src/pages/themes/takeout/sections/footer.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-footer-js" */),
  "component---src-pages-themes-takeout-sections-header-js": () => import("./../../../src/pages/themes/takeout/sections/header.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-header-js" */),
  "component---src-pages-themes-takeout-sections-image-banner-js": () => import("./../../../src/pages/themes/takeout/sections/image-banner.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-image-banner-js" */),
  "component---src-pages-themes-takeout-sections-image-gallery-js": () => import("./../../../src/pages/themes/takeout/sections/image-gallery.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-image-gallery-js" */),
  "component---src-pages-themes-takeout-sections-images-with-text-js": () => import("./../../../src/pages/themes/takeout/sections/images-with-text.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-images-with-text-js" */),
  "component---src-pages-themes-takeout-sections-multicolumn-image-buttons-js": () => import("./../../../src/pages/themes/takeout/sections/multicolumn-image-buttons.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-multicolumn-image-buttons-js" */),
  "component---src-pages-themes-takeout-sections-multicolumn-text-js": () => import("./../../../src/pages/themes/takeout/sections/multicolumn-text.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-multicolumn-text-js" */),
  "component---src-pages-themes-takeout-sections-narrow-banner-js": () => import("./../../../src/pages/themes/takeout/sections/narrow-banner.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-narrow-banner-js" */),
  "component---src-pages-themes-takeout-sections-page-js": () => import("./../../../src/pages/themes/takeout/sections/page.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-page-js" */),
  "component---src-pages-themes-takeout-sections-rich-text-js": () => import("./../../../src/pages/themes/takeout/sections/rich-text.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-rich-text-js" */),
  "component---src-pages-themes-takeout-sections-slideshow-js": () => import("./../../../src/pages/themes/takeout/sections/slideshow.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-slideshow-js" */),
  "component---src-pages-themes-takeout-sections-social-links-js": () => import("./../../../src/pages/themes/takeout/sections/social-links.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-social-links-js" */),
  "component---src-pages-themes-takeout-sections-table-js": () => import("./../../../src/pages/themes/takeout/sections/table.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-table-js" */),
  "component---src-pages-themes-takeout-sections-video-js": () => import("./../../../src/pages/themes/takeout/sections/video.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-video-js" */),
  "component---src-pages-themes-takeout-theme-settings-js": () => import("./../../../src/pages/themes/takeout/theme-settings.js" /* webpackChunkName: "component---src-pages-themes-takeout-theme-settings-js" */)
}

